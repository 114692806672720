.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 640px) {
  .svg-background {
    background-image: url('data:image/svg+xml;utf8,<svg id="visual" viewBox="0 0 900 600" width="900" height="600" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="900" height="600" fill="%23ffffff"></rect><g transform="translate(54.827577699094206 34.9105467842015)"><path d="M291 -104C344.2 69.2 331.7 254.2 228.3 334C125 413.7 -69.2 388.1 -216.7 284.2C-364.2 180.3 -465 -1.8 -421.1 -162.2C-377.3 -322.6 -188.6 -461.3 -34.9 -450C118.9 -438.6 237.8 -277.3 291 -104" fill="%233a4893"></path></g></svg>');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.custom-shape-divider-bottom-1718819907 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1718819907 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
}

.custom-shape-divider-bottom-1718819907 .shape-fill {
  fill: #009d66;
}
